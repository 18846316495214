<!-- 人才管理 -->
<template>
    <div>
        <!-- 通用标题组件 -->

        <el-card class="box-card">
            <el-breadcrumb separator-class="el-icon-arrow-right div40">
                <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
                <el-breadcrumb-item>角色管理</el-breadcrumb-item>
            </el-breadcrumb>
            <div style="text-align: left;">
                <el-button
                        type="primary"
                        size="mini"
                        icon="el-icon-circle-plus-outline"
                        @click="addRoledialog"
                        style="color: #f0f0f0"
                >添加
                </el-button>

            </div>

            <div class="hr-10"></div>

            <el-table :data="tableData" style="width: 100%">

                <el-table-column label="名称" min-width="200" align="left" prop="name"></el-table-column>
<!--                <el-table-column label="角色" width="150px" align="left">-->
<!--                    <template slot-scope="scope">-->
<!--                        <el-tag size="medium" v-show="scope.row.role=='admin'">管理员</el-tag>-->
<!--                        <el-tag size="medium" v-show="scope.row.role=='talent'">人才</el-tag>-->
<!--                        <el-tag size="medium" v-show="scope.row.role=='enterprise'">单位</el-tag>-->
<!--                        <el-tag size="medium" v-show="scope.row.role=='worthyManager'">乡贤</el-tag>-->
<!--                    </template>-->
<!--                </el-table-column>-->
<!--                <el-table-column label="短名" min-width="200" align="left">-->
<!--                    <template slot-scope="scope">-->
<!--                        {{ scope.row.role }}-->
<!--                    </template>-->
<!--                </el-table-column>-->
                <el-table-column label="创建时间" min-width="150px" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.createTime |formatDate}}
                    </template>
                </el-table-column>
                <el-table-column label="修改时间" min-width="150px" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.modifiedTime |formatDate }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" min-width="150px">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑
                        </el-button>
                        <el-button type="danger" size="mini" v-show="isType(scope.row.role)" @click="handleDelete(scope.$index, scope.row)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="hr-20"></div>
            <span class="fl">共计 <b style="color: #1d72c1" v-if="tableData.length" v-text="tableData.length"></b> 个角色</span>
            <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize"
                           @current-change="requestPage"/>
        </el-card>

        <!-- 角色修改-->
        <el-dialog title="角色修改" :visible.sync="dialogVisible" width="60%" :show-close="showClo" >
            <el-card shadow="never">
                <el-form label-width="100px">
                    <el-form-item label="角色名称：">
                        <el-input v-model="newrole.name"></el-input>
                    </el-form-item>
                    <el-form-item label="权限：" style="text-align: left;">
                        <el-checkbox-group v-model="checkedCities" @change="getpermission">
                            <el-checkbox v-for="city in cities" :label="city.id" :key="city.id" style="width: 106px;">
                                {{city.name}}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                </el-form>
            </el-card>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dedePermission">取 消</el-button>
                <el-button type="primary" @click="SetPermission">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 角色添加-->
        <el-dialog title="角色添加" :visible.sync="dialogadd" width="40%" :show-close="showClo">
            <el-card shadow="never">
                <el-form label-width="100px" :model="addrole" ref="addrole">
                    <el-form-item label="角色名称：" prop="name" :rules="[{ required: true, message: '请输入角色名称', trigger: 'blur' }]">
                        <el-input v-model="addrole.name"></el-input>
                    </el-form-item>
<!--                    <el-form-item label="角色：">-->
<!--                        <el-select v-model="role" clearable placeholder="超级管理员" style="width: 100%;">-->
<!--                            <el-option-->
<!--                                    v-for="item in options"-->
<!--                                    :key="item.role"-->
<!--                                    :label="item.label"-->
<!--                                    :value="item.role">-->
<!--                            </el-option>-->
<!--                        </el-select>-->
<!--                    </el-form-item>-->
                    <el-form-item>
                        <el-button @click="dialogadd = false">取 消</el-button>
                        <el-button type="primary" @click="addRole('addrole')">确 定</el-button>
                    </el-form-item>
                </el-form>
            </el-card>

        </el-dialog>

    </div>
</template>

<script>
    //const cityOptions = ['上海', '北京', '广州', '深圳'];
    export default {

        data() {
            return {
                page: 1,
                pages: 0,
                pageSize: 100,
                total: 1,
                createLoading: false,
                checkAll: false,
                showClo: false,
                checkedCities: [],
                cities: '',
                isIndeterminate: true,
                dialogVisible: false,
                dialogadd: false,
                //最新权限id
                newrole: {
                    id: 0,
                    name: '',
                    permissionIds: [],
                },
                //新增角色
                options: [{
                    role: 'admin',
                    label: '超级管理员'
                }, {
                    role: 'talent',
                    label: '人才管理'
                }, {
                    role: 'enterprise',
                    label: '单位管理'
                }, {
                    role: 'worthyManager',
                    label: '乡贤管理员'
                }],
                role: '',

                addrole: {
                    name: '',
                    role: "admin",
                    permissionIds:[1],
                    status: 1
                },
                tableData: []
            };
        },
        created() {
            this.getDate();
        },
        methods: {
          request() {
            let newPage = this.$router.resolve({
              name: 'EntryStoma',
            })
            window.open(newPage.href, '_blank')
          },
            getDate() {
                console.log('Token:'+sessionStorage.getItem("Token"))
                const self = this;
                this.$axios.get('/role/page', {
                    params: {
                        current: 1,
                        size: this.pageSize
                    }
                }).then(function (res) {
                    console.log(res.data.status)
                    if(res.data.status==200){
                        self.tableData = res.data.data.records
                        self.total = res.data.data.total;
                    }

                })
                //查询所以权限
                // this.$axios.get('*').then(function (res) {
                //     //const resit = res.data.data.records
                //     console.log(res)
                //     // for (var i = 0; i < resit.length; i++) {
                //     //     if (resit[i].permission==='article:read'){
                //     //         console.log(resit[i].id)
                //     //         self.addrole.permissionIds.push(resit[i].id)
                //     //     }
                //     // }
                // })
            },


            /**
             * 角色类型判断
             * @param type
             * @returns {boolean}
             */
            isType(type){
              return type!= 'enterprise' &&type!='talent' &&type!='admin'&& type!='worthyManager'?true:false;
            },


            /**
             * 分页
             * @param value number
             */
            requestPage(value) {
                this.$axios.get('/role/page', {
                    params: {
                        current: value,
                        size: this.pageSize,
                    }
                }).then(response => {
                    if(res.data.status==200){
                        this.tableData = res.data.data.records
                        this.total = res.data.data.total;
                    }
                }).catch(error => {
                  console.error(error)
                })
            },


            /**
             * 选择最新权限
             * @param value number
             */
            getpermission(value) {
                console.log(value)
                this.newrole.permissionIds = value
            },


            /**
             * 权限修改
             * @param index number
             * @param row object
             */
            handleEdit(index, row) {
                const self = this;
                this.newrole.id = row.id
                //查询所以权限
                this.$axios.get('/permission/page',{
                    params:{
                        current:1,
                        size:100
                    }
                }).then(function (res) {
                    self.cities = res.data.data.records
                })
                //回显已有权限
                this.$axios.get('/role?id=' + this.newrole.id).then(function (res) {
                    let array = res.data.data.permissions
                    console.log(res)
                    self.newrole.name = res.data.data.name
                    self.checkedCities = []
                    for (var i = 0; i < array.length; i++) {
                        self.checkedCities.push(array[i].id)
                        //console.log(array[i].id)
                        self.newrole.permissionIds.push(array[i].id)
                    }
                })
                this.dialogVisible = true
            },

            //提交
            SetPermission() {
                const self = this;
                this.$axios.put('/role', this.newrole).then(function (res) {
                    if (res.data.status === 200) {
                        self.$message({
                            showClose: true,
                            message: "修改成功",
                            type: "success"
                        });
                        self.dialogVisible = false
                        self.getDate();

                    }
                })
            },
            dedePermission(){
                this.dialogVisible = false
                this.newrole.permissionIds = []

            },
            addRoledialog() {
                this.dialogadd = true;
                this.addrole.name = '';
                this.addrole.role = '';
                //console.log(11111111111111111111111)
            },
            /**
             * 添加角色
             * @param formName object
             */
            addRole(formName){
                const self = this;
                this.addrole.role = this.role;
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        console.log(this.addrole)
                        this.$axios.post('/role',this.addrole).then(function (res) {
                            if (res.data.status === 200) {
                                self.getDate();
                                self.$message({
                                    type: 'success',
                                    message: '成功添加角色，请前往编辑给角色分配权限！'
                                });
                                self.dialogadd = false
                            }
                        })
                    } else {
                        return false;
                    }
                });
            },
            /**
             * 删除角色
             * @param index number
             * @param row object
             */
            handleDelete(index, row) {
                const self = this;
                this.$confirm('确定删除角色?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios.delete('/role?id=' + row.id).then(function (res) {
                        if (res.data.status === 200) {
                            self.$message({
                                type: 'success',
                                message: '成功删除角色!'
                            });
                            self.getDate();

                        }
                    })

                }).catch(() => {

                });



            }
        }
    };
</script>

<style lang="less" scoped>
    .headtit {
        background: #1090e7;
        height: 50px;
        text-align: left;
        line-height: 50px;
        padding: 0 0 0 16px;
        color: #fff;
        font-size: 16px;
    }

    .checkboxlist {
        padding: 15px 0 0 12px;
        height: 100px;
        border: 1px #ddd solid;

        .checkboxitem {
            width: 100px;
            margin-right: 0;
            height: 33px;
        }
    }
    #pagination {
        margin: 32px 0 16px 0;
    }
</style>